<template>
  <v-container id="clients" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'clients-bg.png'+ ')'}" style="height:300px;background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{
              $t("clients")
            }}</h1>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-4">
          <v-col cols="12" class="text-h5 text-center custom-accent--text text-uppercase">{{$t("thesuccessstories")}}</v-col>
        </v-row>
        <transition name="fade" mode="out-in" v-if="client_loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="10"
              lg="10"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="clients.length==0 && !client_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="clients.length && !client_loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('clients.create')"
              cols="12"
              sm="10"
              md="10"
              lg="10">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateClient()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-for="(item, i) in clients.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="10"
              md="10"
              lg="10"
            >
              <v-card
                height="200"
                class="elevation-0"
                outlined
              >
                <v-row class="pa-0 ma-0" style="position:absolute;right:-75px;top:0px;" v-if="user && isLoggedIn">
                  <v-col class="pa-0 ma-0" cols="12">
                    <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('clients.update')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateClient(item)" :title="$t('update')">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('update')}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12">
                    <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('clients.delete')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteClient(item)" :title="$t('delete')">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('delete')}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="5" class="pa-auto ma-auto">
                    <v-img v-if="item.image" class="ma-auto"
                        :src="storage_url+path+item.image"
                        :alt="item.alt"
                        contain
                        aspect-ratio="1"
                        max-height="170"
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="custom-secondary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <v-img v-else contain aspect-ratio="1" max-height="170" :src="storage_url+no_image_path+'noimage.png'" :alt="$t('noimagealt')"></v-img>
                  </v-col>
                  <v-col cols="12" md="7" class="px-4 ma-auto">
                    <v-card-title class="mb-5">
                      <span>{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span>
                    </v-card-title>
                    <v-card-subtitle>
                      <span v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0 mb-4">
          <v-col v-if="Math.ceil(clients.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(clients.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-row data-aos="fade-up" class="custom-accent white--text pa-0 ma-0" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h6 text-uppercase">{{$t("interested")}}</div>
            <v-btn class="my-6" :title="$t('contact')" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("contact")}} {{$t("now")}}!<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>

        <!-- create dialog -->
        <v-dialog v-model="clientcreateoverlay" width="800px">
          <v-card v-if="clientcreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <file-input-drop @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <create-client @isValid="validate" :NewItem="activeclient"></create-client>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="clientcreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearClient()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="client_loading || file_loading" :disabled="!clientcreateformvalid" @click="CreateClient()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="clientupdateoverlay" width="800px">
          <v-card v-if="clientupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
               <file-input-drop v-if="!activeclient.image" @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <update-client @isValid="validate" @deleteFile="OpenDeleteFile" :UpdatedItem="activeclient"></update-client>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="clientupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="client_loading" depressed :disabled="!clientupdateformvalid" @click="UpdateClient()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="clientdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="clientdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="client_loading" depressed @click="DeleteClient()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

        <!-- file delete dialog -->
        <v-dialog v-model="filedeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="filedeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="file_loading" depressed @click="DeleteFile()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- file delete dialog -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import createclient from '../../components/Client/CreateClient.vue';
import updateclient from '../../components/Client/UpdateClient.vue';
import fileinputdrop from "../../components/Admin/FileInputDrop.vue";
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Clients",

  data() {
    return {
      clientcreateoverlay: false,
      clientupdateoverlay: false,
      clientdeleteoverlay: false,
      clientcreateformvalid: false,
      clientupdateformvalid: false,
      activeclient: {},
      page: 1,
      itemsperpage: 5,

      allowedTypes: "image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "268435456",
      allowedLength: 20,
      multiple:false,
      files:[],
      filedeleteoverlay: false,

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "clients/images/",
      no_image_path: "default_images/",
    };
  },
  components: {
    'create-client':createclient,
    'update-client':updateclient,
    'file-input-drop':fileinputdrop,
  },
  async created() {
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('client', {clients:'getClients'}),
    ...mapState("client", {client_loading: "loading"}),
    ...mapState("file", {file_loading: "loading"}),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.clientcreateformvalid=isValid;
            break;

        case 'update':
            this.clientupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    OpenCreateClient() {
      this.clientcreateoverlay = true;
      this.activeclient = {};
    },
    OpenUpdateClient(item) {
      this.clientupdateoverlay = true;
      this.activeclient = {...item};
    },
    OpenDeleteClient(item) {
      this.clientdeleteoverlay = true;
      this.activeclient = {...item};
    },
    OpenDeleteFile() {
      this.filedeleteoverlay = true;
    },
    CreateClient() {
      if(this.clientcreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('clients.create')){
        if(this.activeclient.image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeclient.image = response.data[0].split(';')[0];
            this.addClient(this.activeclient).then(() => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                this.clientcreateoverlay = false;
                this.activeclient = {};
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.addClient(this.activeclient).then(() => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
              this.clientcreateoverlay = false;
              this.activeclient = {};
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }

      }
    },
    UpdateClient() {
      if(this.clientupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('clients.update')){
        if(this.activeclient.image && !this.clients.find(x => x.id === this.activeclient.id).image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeclient.image = response.data[0].split(';')[0];
            this.updateClient(this.activeclient).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                this.clientupdateoverlay = false;
                this.activeclient = {};
                this.UpdateClients(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.updateClient(this.activeclient).then((response) => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
              this.clientupdateoverlay = false;
              this.activeclient = {};
              this.UpdateClients(response.data);
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
      }
    },
    UpdateClients(payload) {
      const edited_item_index=this.clients.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.clients[edited_item_index] = payload;
    },
    DeleteClient() {
      if(this.user && this.user.permission.map(i=>i.name).includes('clients.delete')){
        this.deleteClient(this.activeclient).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.clientdeleteoverlay = false;
            this.activeclient = {};
            if(response.data.image){
              let payload ={name:response.data.image, path:this.path};
              this.deleteFile(payload);
            }
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteFile() {
      if(this.activeclient.image){
        let payload ={name:this.activeclient.image, path:this.path};
        this.deleteFile(payload).then(()=>{
            this.activeclient.image='';
            this.filedeleteoverlay = false;
            this.updateClient(this.activeclient).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                this.UpdateClients(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearClient() {
      this.activeclient={image:this.activeclient.image};
    },
    UploadFiles(files) {
      this.files=files;
      if(!this.multiple)
        this.$set(this.activeclient, 'image', this.files[0]?.name);
    },
    ...mapActions("client", ["addClient","updateClient","deleteClient"]),
    ...mapActions("file", ["addFile","deleteFile"]),
  },
};
</script>
<style scoped>
@import url("../../../src/assets/styles/clients.css");
</style>
